import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CalculationStatus } from "@/types/calculation.types";
import { Formulation } from "@/types/formulation.types";

export type RequestErrorState = {
    status: number | undefined;
    errorMessages?: string[];
};

export type CalculationMetadata = {
    submittedFormulation: Formulation | null;
};

export type CalculationStoreState = {
    id: string | null;
    status: CalculationStatus | null;
    requestError: RequestErrorState | null;
    metadata: CalculationMetadata;
};

const INITIAL_STATE: CalculationStoreState = {
    id: null,
    status: null,
    requestError: null,
    metadata: {
        submittedFormulation: null,
    },
};

const calculationSlice = createSlice({
    name: "calculation",
    initialState: INITIAL_STATE,
    reducers: {
        setCalculationId: (state, action: PayloadAction<string | null>) => {
            state.id = action.payload;
        },
        setCalculationRequestError: (state, action: PayloadAction<RequestErrorState>) => {
            state.requestError = action.payload;
        },
        setCalculationStatus: (state, action: PayloadAction<CalculationStatus | null>) => {
            state.status = action.payload;
        },
        setCalculationMetadata: (state, action: PayloadAction<Partial<CalculationMetadata>>) => {
            state.metadata = { ...state.metadata, ...action.payload };
        },
        resetCalculation: () => INITIAL_STATE,
    },
});

export const {
    setCalculationId,
    setCalculationRequestError,
    setCalculationStatus,
    setCalculationMetadata,
    resetCalculation,
} = calculationSlice.actions;
export const calculationReducer = calculationSlice.reducer;
