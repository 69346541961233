import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
    accessToken: string | null;
};

const INITIAL_AUTH_STATE: AuthState = {
    accessToken: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState: INITIAL_AUTH_STATE,
    reducers: {
        setAuth: (state, action: PayloadAction<AuthState>) => {
            state.accessToken = action.payload.accessToken;
        },
        clearAuth: () => INITIAL_AUTH_STATE,
    },
});

export const { setAuth, clearAuth } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectAccessToken = (state: { auth: AuthState }) => state.auth.accessToken;
