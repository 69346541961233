import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    DEFAULT_TEMPERATURE_KEY,
    temperatures,
} from "@/pages/CalculationPage/sections/conditions/components/TemperatureSelect/TemperatureSelect.consts";
import { Conditions, Formulation } from "@/types/formulation.types";
import { Ingredient } from "@/types/ingredient.types";

type FormulationStoreState = Formulation;

const INITIAL_STATE: FormulationStoreState = {
    conditions: {
        temperature: temperatures[DEFAULT_TEMPERATURE_KEY],
    },
    ingredients: [],
};

const formulationSlice = createSlice({
    name: "formulation",
    initialState: INITIAL_STATE,
    reducers: {
        setIngredients: (state, action: PayloadAction<Ingredient[]>) => {
            state.ingredients = action.payload;
        },
        setIngredientProportion: (state, action: PayloadAction<{ id: number; proportion: number }>) => {
            const ingredientIndex = state.ingredients.findIndex((ingredient) => ingredient.id === action.payload.id);
            if (ingredientIndex !== -1) {
                state.ingredients[ingredientIndex].currentProportion = action.payload.proportion;
            }
        },
        updateConditions: (state, action: PayloadAction<Partial<Conditions>>) => {
            state.conditions = {
                ...state.conditions,
                ...action.payload,
            };
        },
    },
});

export const { setIngredients, setIngredientProportion, updateConditions } = formulationSlice.actions;
export const formulationReducer = formulationSlice.reducer;
